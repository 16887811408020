import '../App.css';
import Posts from './Posts';

function Home() {
  return (
    <div className="App">
        <Posts />
    </div>
  );
}

export default Home;
